import React, { useState, useEffect } from "react";
import { withPrefix, Link, navigate } from "gatsby";
import { useApplicationContext } from "../../provider";
import { useLocation } from "@reach/router";
import axios from "axios";
import Helmet from "react-helmet";
import $ from "jquery";
import styled from "@emotion/styled";
import Header1 from "../components/header-1";
import Footer1 from "../components/footer-1";
import {
  setUser,
  handleDiscordSignIn,
  handleGoogleSignIn,
  setAccessToken,
} from "../services/auth";
import { BASE_URL } from "../services/apiUrl";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, selectAuthState } from "../sagas/auth/authSlice";

const ForgotPasswordText = styled.div`
  @media (max-width: 768px) {
    text-align: center;
    margin-right: 5px;
    margin-top: 10px;
    font-size: 16px;
  }

  @media (min-width: 769px) {
    text-align: right;
    margin-right: 5px;
    font-size: 16px;
    margin-top: 8px;
  }
`;
const EyeIcon = styled.div`
  width: 20px;
  position: relative;
  top: 44px;
  right: 18px;
  z-index: 1;
`;
const AlertBox = styled.div`
  padding: 5px;
  margin-bottom: 15px;
`;
export default function Layout() {
  const dispatch = useDispatch();
  const { isResettingPasswordSuccess, isResettingPasswordFailed } = useSelector(selectAuthState);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [resetEmail, setResetEmail] = useState("");
  const [isConfirm, setIsConfirm] = useState("");

  const { applicationState, setApplicationState, creditCount, setCreditCount } =
    useApplicationContext();

  useEffect(() => {
    if (query.get("redirect") === "discord") {
      if (query.get("code")) {
        handleDiscordSignIn(query.get("code"));
      }
    }
  }, []);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setApplicationState({ ...applicationState, email: state.email });
  };
  const clearFormField = () => {
    setState({
      ...state,
      email: "",
      password: "",
    });
    setResetEmail("");
    setIsConfirm("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${BASE_URL}/api/v1/auth/login`, {
        email: state.email,
        password: state.password,
      });

      if (!res.data?.IsUserOnWhiiteList) {
        $("#password").parents("li").find(".required-txt").remove();
        $("#password").after(`
          <div class="required-txt">User registration is for whitelisted e-mails only. Please come back later.</div>
        `);
      } else {
        if (res.data.status) {
          setUser(res.data);
          setAccessToken(res.data.access_token);
          setApplicationState({
            ...applicationState,
            user_role: res.data.role,
          });
          setCreditCount(res.data.credits);
          localStorage.setItem("baseUrl", BASE_URL);
          navigate("/");
        }
      }
    } catch (error) {
      $("#password").parents("li").find(".required-txt").remove();
      $("#password").after(`
        <div class="required-txt">* Invalid Email or Password</div>
      `);
    }
  };

  const handleDiscordSigninPopup = (e) => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=0,height=0,left=-1000,top=-1000`;

    window.open(process.env.DISCORD_CODE_REDIRECT_URI, "this", params);
  };

  const confirmPasswordReset = async () => {
    if (resetEmail !== "") {
      dispatch({
        type: resetPassword.type,
        payload: { email: resetEmail }
      });
    }
  };

  useEffect(() => {
    if (isResettingPasswordSuccess) {
      localStorage.setItem("emailForResetPassword", resetEmail);
      localStorage.setItem("passwordResetStatus", true);
      setIsConfirm("success");
    } else if (isResettingPasswordFailed) {
      setIsConfirm("failed");
    }
  }, [isResettingPasswordSuccess, isResettingPasswordFailed])

  const [passwordShown1, setpasswordShown1] = useState(false);

  const togglePassword = () => {
    setpasswordShown1(!passwordShown1);
  };

  return (
    <>
      <div className="container-main" id="page">
        <Helmet>
          <link
            href={withPrefix("assets/css/progress-bar.css")}
            rel="stylesheet"
          />
          <script
            src={withPrefix("assets/js/progress-bar.js")}
            type="text/javascript"
          />
          <style type="text/css">{`
            .reset-password-modal .btn.reset-password-btn {
              color: #fff;
              background: #5757ff;
            }

            .reset-password-btn:before {
              display: block !important;
              background: #8f0ced;
            }
          `}</style>
        </Helmet>
        <Header1></Header1>
        <main className="content-main">
          <div className="form-container">
            <div className="container">
              <div className="container-box">
                <div className="sign-in-form">
                  <div className="box">
                    <div className="heading-top">
                      <h3>Sign in</h3>

                      <p>
                        <Link to="/create-account">Create an Account</Link>
                      </p>
                    </div>
                    <div className="form-field">
                      <form action="" method="post" style={{ width: "100%" }}>
                        <div className="input-out">
                          <label>Email</label>
                          <input
                            id="email"
                            name="email"
                            type="text"
                            value={state.email}
                            onChange={onChange}
                            placeholder="name@domain.com"
                          />
                        </div>
                        <div className="input-out">
                          <div style={{ display: "flex" }}>
                            <label>Password</label>
                            <EyeIcon
                              className="view1"
                              style={{ width: "100%" }}
                              onClick={togglePassword}
                            >
                              <em>
                                <img
                                  style={{ width: "20px" }}
                                  src={
                                    passwordShown1
                                      ? withPrefix(
                                          "assets/img/eye-slash-solid.svg"
                                        )
                                      : withPrefix("assets/img/eye-solid.svg")
                                  }
                                  onClick={togglePassword}
                                  alt="eye"
                                />
                              </em>
                            </EyeIcon>
                          </div>
                          <li className="signinli">
                            <input
                              id="password"
                              name="password"
                              type={passwordShown1 ? "text" : "password"}
                              value={state.password}
                              onChange={onChange}
                            />
                          </li>
                        </div>
                        <div className="btn-out">
                          <button className="btn" onClick={handleSubmit}>
                            Sign in
                          </button>
                        </div>
                        <ForgotPasswordText className="password-link">
                          <a href="#send-order" data-bs-toggle="modal">
                            Forgot Password?
                          </a>
                        </ForgotPasswordText>
                      </form>
                    </div>
                    <div className="or-circle">
                      <span>OR</span>
                    </div>
                    <div className="bottom-btns">
                      <ul>
                        <li>
                          <a>
                            <em>
                              <img
                                src={withPrefix("assets/img/icon-apple.png")}
                                alt="icon-apple"
                              />
                            </em>{" "}
                            Apple
                          </a>
                        </li>
                        <li>
                          <a onClick={handleGoogleSignIn}>
                            <em>
                              <img
                                src={withPrefix("assets/img/icon-google.png")}
                                alt="icon-google"
                              />
                            </em>{" "}
                            Google
                          </a>
                        </li>
                        <li>
                          <a onClick={handleDiscordSigninPopup}>
                            <em>
                              <img
                                src={withPrefix("assets/img/icon-discord.png")}
                                alt="icon-discord"
                              />
                            </em>{" "}
                            Discord
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
      <div className="profile-modal reset-password-modal">
        <div className="modal fade" id="send-order" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="profile-popup">
                <div className="box">
                  <div className="icon-user">
                    <img
                      width={50}
                      src={withPrefix("assets/img/reset-icon-1.png")}
                      alt="icon-user"
                    />
                  </div>
                  <h3 className="text-center">Reset Password</h3>
                  {isConfirm === "success" && (
                    <AlertBox>
                      <Alert severity="success" sx={{ color: '#5757ff', background: '#f6f5ff', '& .MuiAlert-icon': { color: '#5757ff' } }}>
                        Email Sent - Please Check Your Inbox
                      </Alert>
                    </AlertBox>
                  )}
                  {isConfirm === "failed" && (
                    <AlertBox>
                      {" "}
                      <Alert severity="error" sx={{ color: '#5757ff', background: '#f6f5ff', '& .MuiAlert-icon': { color: '#5757ff' } }}>
                        Something went wrong !
                      </Alert>
                    </AlertBox>
                  )}
                  <div className="form-field alt">
                    <p>
                      {" "}
                      Enter your email address in the form below and we will
                      send you further instructions on how to reset your
                      password.
                    </p>
                    <ul>
                      <li>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          value={resetEmail}
                          onChange={(e) => setResetEmail(e.target.value)}
                          placeholder="Email address"
                        />
                      </li>
                    </ul>
                    <div className="btn-out">
                      <a
                        href="#"
                        aria-disabled
                        onClick={confirmPasswordReset}
                        className="btn reset-password-btn"
                      >
                        Reset Password
                      </a>
                    </div>
                  </div>

                  <div
                    onClick={() => clearFormField()}
                    className="icon-close"
                    data-bs-dismiss="modal"
                  >
                    <img
                      src={withPrefix("assets/img/icon-close.png")}
                      alt="icon-close"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
